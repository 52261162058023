import supportedLanguages from './supported-languages';
import defaultGlobalClientConfig, {
  IGlobalClientConfig,
} from '../../../default/config/GlobalClientConfig/GlobalClientConfig';
import {
  AuthenticationFlowTypes,
  FeaturedEventsCarouselType,
} from '../../../../config/Enums/App';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

const GlobalClientConfig: IGlobalClientConfig = {
  ...defaultGlobalClientConfig,
  siteTitle: 'DaModsStore',
  clientName: 'DaModsStore',
  copyrightName: 'DaModsStore',
  clientOrganizationSiteUrl: 'https://damods.store/',
  marketingSiteUrl: 'https://damods.store',
  accountSetupRoute: Routes.subscribe,
  homeRedirectTo: Routes.store,
  storeOnly: true,
  nav: { orientation: 'hidden' },
  socialLinks: {
    facebook: 'https://www.facebook.com/Influencer/',
    twitter: 'https://twitter.com/Influencer',
    instagram: 'https://www.instagram.com/Influencer/',
    youtube: 'https://www.youtube.com/Influencer',
    discord: 'https://discord.gg/xKvzQ3ww6b',
    email: 'mailto:influencer@mail.com',
  },
  currency: {
    showOnlyPoints: true,
  },
  numberOfEventsToShowOnHomePage: 2,
  numberOfServersToShowOnHomePage: 2,
  authenticationFlowType: AuthenticationFlowTypes.Carousel,
  featuredEventsCarouselType: FeaturedEventsCarouselType.Centered,
  streams: {
    headerType: 'carousel',
  },
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  eventDiscordLink: 'https://discord.gg/influencerCode',
  msisdn: {
    requiredness: MsisdnRequiredness.OPTIONAL,
  },
  countryCode: 788,
  colors: {
    ...defaultGlobalClientConfig.colors,
    darkGrey: '#a5a5a5',
    lightGrey: '#c2c6d5',
    black: '#0f0f0f',
    white: '#f4f9ff',
    primary: '#610da1',
    secondary: 'black',
    danger: 'red',
    success: '#80ff80',
    warning: '#ffcb50',
    dark: '#1a1a1a',
    light: '#c2c6d5',
  },
};

export default GlobalClientConfig;
